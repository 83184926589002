import { QueryClient } from 'react-query'
import { createContext } from 'react'
import axios, { AxiosInstance } from 'axios'
import { RequestRole } from '../lib/withDiaperBankAuthentication'
import type * as Jwt from './meatballJwt'
export interface Ctx {
  accessToken?: string
  setAccessToken: (s: string) => void
  jwtToken?: Jwt.MeatballJwtClaims
  apiClient: AxiosInstance
  queryClient: QueryClient
  role: RequestRole
  setRole: (r: RequestRole) => void
  clearAccessToken(): void
}

const Context = createContext<Ctx>({
  apiClient: axios,
  setAccessToken: (a: string) => {},
  queryClient: new QueryClient(),
  role: 'public',
  setRole(r: RequestRole) {},
  clearAccessToken() {},
})
export default Context
