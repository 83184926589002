import { faCheckCircle, faCircle, faExclamationCircle, faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { toast, ToastOptions } from 'react-toastify'
export const toastMessage = (options: ToastOptions & { title: string; body?: string }) => {
  toast(
    ({ closeToast, toastProps }) => (
      <div className='p-4'>
        <div className='flex items-start'>
          <div className='flex-shrink-0'>
            {options.type &&
              (() => {
                if (options.type === 'error') return <FontAwesomeIcon icon={faTimesCircle} className='h-6 w-6 text-red-400' aria-hidden={true} />
                if (options.type === 'success') return <FontAwesomeIcon icon={faCheckCircle} className='h-6 w-6 text-success' aria-hidden={true} />
                if (options.type === 'info') return <FontAwesomeIcon icon={faInfoCircle} className='h-6 w-6 text-info' aria-hidden={true} />
                return <FontAwesomeIcon icon={faExclamationCircle} className='h-6 w-6 text-yellow-400' aria-hidden={true} />
              })()}
          </div>
          <div className='ml-3 w-0 flex-1 pt-0.5'>
            <p className='text-sm font-medium text-gray-900'>{options.title}</p>
            {options.body && <p className='mt-1 text-sm text-gray-700'>{options.body}</p>}
          </div>
          <div className='ml-4 flex-shrink-0 flex'>
            <button
              className='bg-white rounded-md inline-flex text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={closeToast}
            >
              <span className='sr-only'>Close</span>
              <FontAwesomeIcon icon={faTimesCircle} className='h-6 w-6' aria-hidden={true} />
            </button>
          </div>
        </div>
      </div>
    ),
    { ...options, closeButton: <></> },
  )
}
